<template>
  <a-modal
    wrap-class-name="account-insert"
    :visible="visible"
    :title="$t('pages_pay_153')"
    :mask-closable="false"
    :confirm-loading="loading"
    @ok="handleSubmit"
    @cancel="$emit('update:visible', false)"
  >
    <a-form
      ref="accountRef"
      :model="accountForm"
      :rules="accountRules"
      class="account-form"
      layout="vertical"
    >
      <a-form-item name="accountName" :label="$t('pages_pay_141')">
        <a-input
          v-model:value="accountForm.accountName"
          disabled
          allow-clear
          :placeholder="$t('common_text_006') + $t('pages_pay_141')"
        />
      </a-form-item>
      <a-form-item name="bankNo" :label="$t('pages_pay_142')">
        <a-input
          v-model:value="accountForm.bankNo"
          :placeholder="$t('common_text_006') + $t('pages_pay_142')"
          allow-clear
        />
      </a-form-item>
      <a-form-item name="bankName" :label="$t('pages_pay_143')">
        <a-input
          v-model:value="accountForm.bankName"
          :placeholder="$t('common_text_006') + $t('pages_pay_143')"
          allow-clear
        />
      </a-form-item>
      <a-form-item name="bankAddr" :label="$t('pages_pay_148')">
        <a-input
          v-model:value="accountForm.bankAddr"
          :placeholder="$t('common_text_006') + $t('pages_pay_148')"
          allow-clear
        />
      </a-form-item>
      <a-form-item name="swiftCode" label="SWIFT CODE">
        <a-input
          v-model:value="accountForm.swiftCode"
          :placeholder="$t('common_text_006') + 'SWIFT CODE'"
          allow-clear
          @blur="handleSwiftBlur"
        />
      </a-form-item>
      <a-form-item name="areaName" :label="$t('pages_pay_381')">
        <a-input
          v-model:value="accountForm.areaName"
          disabled
        />
      </a-form-item>
      <a-form-item name="currencyList" :label="$t('pages_pay_154')">
        <a-select
          v-model:value="accountForm.currencyList"
          mode="multiple"
          show-search
          allow-clear
          option-label-prop="label"
          option-filter-prop="label"
          :placeholder="$t('common_text_005') + $t('pages_pay_154')"
        >
          <a-select-option
            v-for="item in curList"
            :key="item.currency"
            :value="item.currency"
            :label="`${item.currency} - ${item.currencyName}`"
          >
            <img :src="getCountryLogo(item.currency)" class="select-img-country">
            <span>{{ item.currency }} - {{ item.currencyName }}</span>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item name="proxyBankName" :label="$t('pages_pay_155')">
        <a-input
          v-model:value="accountForm.proxyBankName"
          :placeholder="$t('common_text_006') + $t('pages_pay_155')"
          allow-clear
        />
      </a-form-item>
      <a-form-item name="proxyBankAddr" :label="$t('pages_pay_156')">
        <a-input
          v-model:value="accountForm.proxyBankAddr"
          :placeholder="$t('common_text_006') + $t('pages_pay_156')"
          allow-clear
        />
      </a-form-item>
      <a-form-item name="proxyBankSwiftCode" :label="$t('pages_pay_157')">
        <a-input
          v-model:value="accountForm.proxyBankSwiftCode"
          :placeholder="$t('pages_pay_157')"
          allow-clear
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { getCountryLogo } from '@/util'
import { _pay, _receive } from '@/api'
import i18n from '@/locale'
export default {
  name: 'AccountInsert',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    curList: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, ctx) {
    const accountRef = ref(null)
    const store = useStore()
    const state = reactive({
      loading: false,
      areaList: [],
      accountForm: {
        accountName: store.getters.getUserInfo.merchantAccountName,
        bankNo: '',
        bankName: '',
        bankAddr: '',
        swiftCode: '',
        areaName: '',
        currencyList: [],
        proxyBankName: '',
        proxyBankAddr: '',
        proxyBankSwiftCode: ''
      },
      accountRules: {
        accountName: [{ required: true }],
        bankNo: [{ required: true }],
        bankName: [{ required: true }],
        bankAddr: [{ required: true }],
        swiftCode: [{ required: true }],
        areaName: [{ required: true }],
        currencyList: [{ required: true }]
      }
    })

    const handleSwiftBlur = () => {
      const { swiftCode } = state.accountForm
      if (swiftCode[4] && swiftCode[5]) {
        state.accountForm.areaName = ''

        const areaCode = `${swiftCode[4]}${swiftCode[5]}`.toLocaleUpperCase()
        const area = state.areaList.find(item => item.areaCode === areaCode)

        if (area) {
          state.accountForm.areaName = `${area.areaEnglishName} ${area.areaChineseName}`
        } else {
          message.destroy()
          message.error(i18n.global.t('pages_pay_382'))
        }
      }
    }

    const handleSubmit = () => {
      accountRef.value.validateFields()
        .then(values => {
          state.loading = true
          const data = Object.assign({}, values, {
            currencies: values.currencyList.join(',')
          })
          return _pay.addOrUpdateAccount(data)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('pages_pay_158'))
            ctx.emit('update:visible', false)
            ctx.emit('refresh')
            accountRef.value.resetFields()
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    const init = async () => {
      const res = await _receive.getAreaPub()
      if (res.data.succ) state.areaList = res.data.data
    }

    init()

    return {
      accountRef,
      getCountryLogo,
      handleSwiftBlur,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.account-insert {
  .account-form {
    margin-top: -12px;
  }
}
</style>

